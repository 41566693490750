import React from 'react';
import { AppProps, AppContext } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import { wrapper } from 'stores/store';
import 'styles/globals.scss';
import 'swiper/swiper.scss';
import 'react-datepicker/dist/react-datepicker.css';

const App: React.FC<AppProps> = props => {
  const { Component, pageProps, router } = props;

  const propsCurrent = {
    ...pageProps,
    router,
  };

  return <Component {...pageProps} />;
};

export default wrapper.withRedux(appWithTranslation(App));
