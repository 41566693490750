import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';

import orderStore from './orderStore';

const initStore = () =>
  configureStore({
    reducer: {
      order: orderStore,
    },
    devTools: true,
  });

export const wrapper = createWrapper(initStore);
